
<template>
  <div>
    <div v-if="!editing">
      <span class='text' @click="enableEditing">{{ originalTitle }}</span>
    </div>
    <div v-if="editing">
      <div v-if="bigText">
        <textarea v-model="tempValue" type='text' class='form-control' :placeholder="originalTitle" />
      </div>
      <div v-else>
        <input v-model="tempValue" type='text' class='form-control' :placeholder="originalTitle" />
      </div>
      &nbsp;&nbsp;&nbsp;
      <button class="btn btn-primary" @click="disableEditing"> Cancel </button>
      &nbsp;&nbsp;&nbsp;
      <button class="btn btn-primary" @click="saveEdit(bunchId)"> Save </button>
    </div>
  </div>
</template>
<script>


export default {
  emits: ['save-edit'],
  props: {
    bunchId: Number,
    originalTitle: String,
    bigText: { type: Boolean, default: false }
  },

  data() {
    return {
      tempValue: null,
      editing: false,
    };
  },
  methods: {
    enableEditing: function () {
      this.tempValue = this.originalTitle;
      this.editing = true;
    },
    disableEditing: function () {
      this.tempValue = null;
      this.editing = false;
    },
    saveEdit: function (bunchId) {
      // However we want to save it to the database
      this.$emit('save-edit', this.tempValue, bunchId);
      this.value = this.tempValue;
      this.disableEditing();
    }
  }
}
</script>
<style>
.span {
  display: block
}
</style>