<template>
    <div>
        <div class="container">
            <div class="row m-12 justify-content-center align-items-center">
                <div class="col-11 align-content-center justify-content-center" style="text-align: center">
                    <h3 v-if="data_loaded">
                        {{ this.title }}
                    </h3>
                    <h3 v-else>
                        Загружаем...
                    </h3>
                </div>


                <div class="col-12 align-content-left justify-content-left" style="text-align: center">
                    <a class="btn btn-outline-secondary mt-2 mr-2 mb-2" @click="graphOpened = !graphOpened">
                        <h3>Динамика</h3>
                    </a>
                </div>

                <div v-if="graphOpened" class="col-12">
                    <apexchart ref="theChart" type="scatter" height="350" :options="chartOptions" :series="series">
                    </apexchart>
                </div>

                <div>


                    <div class="col-12 align-content-left justify-content-left" style="text-align: center">
                        <a class="btn btn-outline-secondary mt-2 mr-2 mb-2" @click="suzhetOpened = !suzhetOpened">
                            <h3>Сюжеты</h3>
                        </a>
                    </div>

                    <div v-if="suzhetOpened">
                        <vs-table v-model="selectedBunch" pagination max-items="30" :data="bunches_list">
                            <template slot="thead">
                                <vs-th style="width:70%" sort-key="bunch_title" id="nameCol">Сюжет</vs-th>
                                <vs-th style="width:15%" sort-key="msg_num" id="numCol">Сообщений за 72 часа</vs-th>
                                <vs-th style="width:15%" sort-key="last_message_dt" id="timeCol">Последнее сообщение в
                                    сюжете</vs-th>

                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :data="x" :key="indextr" v-for="(x, indextr) in data">
                                    <vs-td style="width:70%" :data="x.bunch_title">{{ x.bunch_title }}</vs-td>
                                    <vs-td style="width:15%" :data="x.msg_num">{{ x.msg_num }}</vs-td>
                                    <vs-td style="width:15%" :data="x.last_message_dt">{{ x.last_message_dt }}</vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>



                    <div class="col-12 align-content-left justify-content-left" style="text-align: center">
                        <a class="btn btn-outline-secondary mt-2 mr-2 mb-2" @click="msgOpened = !msgOpened">
                            <h3>Сообщения</h3>
                        </a>
                    </div>
                    <div v-if="msgOpened">
                        <vs-table :data="messages_list" pagination max-items="30" v-model="selectedMessage">
                            <template #thead>
                                <vs-th sort-key="text" id="nameCol" style="width: 75%; text-align: center">Текст</vs-th>
                                <vs-th sort-key="dup_count" id="numCol" style="width: 15%; text-align: center">Количество
                                    дублей</vs-th>
                                <vs-th sort-key="last_publication" id="timeCol"
                                    style="width: 15%; text-align: center">Последняя публикация</vs-th>
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :data="m" :key="indextr" v-for="(m, indextr) in data">
                                    <vs-td :data="m.text" style="text-align: left; word-wrap: break-word">{{ m.text
                                    }}</vs-td>
                                    <vs-td :data="m.dup_count" style="text-align: center; word-wrap: break-word">{{
                                        m.dup_count
                                    }}</vs-td>
                                    <vs-td :data="m.last_publication" style="text-align: center; word-wrap: break-word">{{
                                        m.last_publication }}
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

import store from "@/store/store";
import { resourceUsage } from "process";

import { ArrowUpIcon, ArrowDownIcon, HelpCircleIcon, ArrowRightCircleIcon } from "vue-feather-icons";
import EditableText from './editableText.vue';
import TagButton from './tagButton.vue';

export default {
    name: "topic-modelling-history",
    components: {
        ArrowUpIcon,
        ArrowDownIcon,
        ArrowRightCircleIcon,

        HelpCircleIcon,
        EditableText,
        TagButton,
    },
    data() {
        return {
            selectedBunch: {},
            selectedMessage: {},
            currentSort: 'rank',
            currentSortDir: 'asc',
            opened: [],
            suzhetOpened: true,
            msgOpened: true,
            graphOpened: false,
            dateOptions: { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
            bunches_list: [],
            messages_list: [],
            currentSort: 'date',
            currentSortDir: 'asc',
            bunch_name: 'Загружаем...',
            opened: false,
            data_loaded: false,
            neid: -10,
            series: [
                {
                    name: "Сообщений с сущностью",
                    data: [],
                    type: 'bar',
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'История сущности',
                    align: 'left'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'd MMM, HH:mm',
                        datetimeUTC: false
                    }
                },
                yaxis: [
                    {
                        seriesName: "Сообщений с сущностью",
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                        },
                        title: {
                            text: "Сообщений с сущностью",
                        },
                    },
                    {
                        seriesName: "Сообщений с сущностью",
                        show: false,
                    },
                ],
                tooltip: {
                    // custom: [
                    //     function ({ series, seriesIndex, dataPointIndex, w }) {
                    //         return '<div>' + '<span><b>' + series[seriesIndex][dataPointIndex] + '</b> сообщений в кластере</span>' + '<br>' + '<br>' +
                    //             '<span>' + w.globals.initialSeries[seriesIndex].data[dataPointIndex].text + '</span></div>';
                    //     },
                    //     function ({ series, seriesIndex, dataPointIndex, w }) {
                    //         return '<div class="arrow_box">' +
                    //             '<span>' + series[seriesIndex][dataPointIndex] + ' сообщений за час' + '</span>' +
                    //             '</div>';
                    //     },
                    // ],

                    shared: false,
                    intersect: true,
                    x: {
                        format: 'd MMM, HH:mm',
                        // show: false
                    }
                },
            },
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        prettyDt(date) {
            return new Date(date).toLocaleTimeString('ru-RU', this.dateOptions);
        },
        getHostname(stream) {
            let basic_url = ''
            if (stream == "SberBA") {
                basic_url = basic_url + this.$hostname_ba
            } else {
                basic_url = basic_url + this.$hostname_ba_macro
            }
            return basic_url;
        },
        getData() {
            this.data_loaded = false;
            let requestData = {
                ne_id: parseInt(this.$route.params.neId)
            };
            console.log(this.$route);
            this.axios({
                method: "post",
                url: this.getHostname(this.$route.params.stream) + "/plot-modelling/get-ne-info",
                data: requestData,
            }).then((res) => {
                this.neid = res.data.id;
                this.text = res.data.text;
                this.ne_type = res.data.ne_type;
                this.created_ts = res.data.created_ts;
                this.is_name = res.data.is_name;
                this.timeseries = res.data.timeseries;
                this.messages_list = res.data.messages;
                this.bunches_list = res.data.bunches;


                switch (this.ne_type) {
                    case "LOC":
                        this.ne_type_for_title = "Локация";
                        break;
                    case "ORG":
                        this.ne_type_for_title = "Организация";
                        break;
                    case "PER":
                        this.ne_type_for_title = "Персона";
                        break;
                    default:
                        this.ne_type_for_title = "Сущность";
                };
                this.title = '' + this.ne_type_for_title + ' "' + this.text + '"';
                this.series[0].data = this.timeseries;
                this.data_loaded = true;
                console.log(this.bunches_list);
            });
        },
        toggleOpened() {
            this.opened = ~this.opened;
        },


        getHistory(bunch_id) {
            let url = '' + '/topic-modelling-live/bunchHistory/' + bunch_id + '/' + this.$route.params.stream;
            window.open(url, '_blank');

        },
        getUrl(url) {
            window.open(url, '_blank');

        },
    },
    watch: {
        selectedBunch(value) {
            if (value != false) {
                this.getHistory(value.bunch_id);
                this.selectedBunch = false;
            }
        },
        selectedMessage(value) {
            console.log(value);

            if (value != false) {
                console.log(value);
                this.getUrl(value.links[0]);
                this.selectedMessage = false;
            }
        },
    },
    computed: {
        rowStyle() {
            return "{background: cadetblue;color:cadetblue;pointer-events:auto;cursor:pointer;}";
        }
        // sorted_history_list: function () {
        //     let mod = this.currentSortDir == 'desc' ? -1 : 1;
        //     return this.bunches.sort((a, b) => (Date.parse(a[0].date) - Date.parse(b[0].date)) * mod);
        // },

        // sorted_bunches_list: function () {

        //     this.loading = true;
        //     let a = this.bunches_list.sort((a, b) => {
        //         let modifier = 1;
        //         if (this.currentSortDir === 'desc') modifier = -1;
        //         if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        //         if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        //         return 0;
        //     });
        //     console.log('sorted bunches list', a);
        //     console.log('bunches list', this.bunches_list);
        //     this.loading = false;
        //     return a;
        // },
    },


};
</script>
  
<style >
svg>text {
    fill: white;
}

.apexcharts-tooltip.apexcharts-theme-light {
    background: rgb(31 45 61)
}

.apexcharts-text tspan {
    fill: white;
}

.apexcharts-menu {
    background: rgb(31 45 61);
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: rgb(187, 219, 255)
}

.tooltip-container {
    max-width: 300px;
}

.tooltip-text {
    flex-wrap: wrap;
}

.apexcharts-tooltip {
    white-space: pre-wrap !important;
    max-width: 200px;
}

.apexcharts-legend-text {
    color: white !important;
}

.vs-con-table table {
    background: rgb(31 45 61);
}

tr {
    background: rgb(40 61 85) !important;
}

td {
    background: rgb(50 71 95);
}

thead {
    display: table-header-group;
}
</style>