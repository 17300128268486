<template>
    <div>
        <h6 :style="styleObject" @click="$emit('btnClicked')" @mouseover="mo_event(true)" @mouseleave="mo_event(false)"
            :class="getclass()"><b>{{
                displayedText
            }}</b></h6>

    </div>
</template>

<script>
export default {
    props: ['buttonText', 'tagId', 'bunchId', 'isDeletable'],
    emits: ['remove-tag-finalize', 'btnClicked'],
    data() {
        return {
            button: {
                // colorBackd: '#00BF56',
                colorBackd: '#00007F',
                colorBackdHover: '#000000',
                text: 'Results',
                // color: '#d3e0ff',
                color: '#FEFE00',
                colorHover: "#E02629",
                borderColor: '#d3e0ff',
                borderColorHover: "#E02629"
            },
            upHere: false,
        }
    },
    methods: {
        getclass() {
            let st = "border tagbutton border-primary badge rounded-pill";
            if (this.isDeletable == true) {
                st = st + " tagbuttonact";
            }
            return st;


        },
        mo_event(status) {
            if (this.isDeletable) {
                this.upHere = status
            }

        },
        getHostname(stream) {
            let basic_url = ''
            if (stream == "SberBA") {
                basic_url = basic_url + this.$hostname_ba
            } else {
                basic_url = basic_url + this.$hostname_ba_macro
            }
            return basic_url;
        },
        btnClickedInternal() {
            console.log('btn clicked');
            if (this.isDeletable) {
                let requestData = {
                    bunch_id: this.bunchId,
                    tag_id: this.tagId
                };
                this.axios({
                    method: "post",
                    url: this.getHostname(this.$route.params.stream) + "/plot-modelling/detach-tag",
                    data: requestData,
                }).then((res) => {
                    this.$emit('remove-tag-finalize', this.tagId);
                    console.log('tag removed');

                })
            }
        },



    },
    computed: {
        displayedText() {
            return this.upHere == true ? 'удалить тэг' : this.buttonText;
        },

        styleObject() {
            return {
                '--button-color': this.button.color,
                '--button-background-color': this.button.colorBackd,
                '--button-border-color': this.button.borderColor,

                '--button-color--hover': this.button.colorHover,
                '--button-background-color--hover': this.button.colorBackdHover,
                '--button-border-color': this.button.borderColorHover
            };
        },
    },
}
</script>

<style>
.tagbutton {
    color: var(--button-color);
    background-color: var(--button-background-color);
    border-color: var(--button-border-color);
}

.tagbuttonact:hover {
    color: var(--button-color--hover);
    background-color: var(--button-background-color--hover);
    border-color: var(--button-border-color--hover);
}
</style>


